import React from 'react';
import { VStack, Text, Button, Grid } from '@chakra-ui/react';

import AdvantageCard from '~entities/AdvantageCard';
import { goToForm } from 'shared/lib/goToForm';

import { greetingsCardsData, greetingsCardsStyles } from 'constants/1C/greetings';

export default function Greetings1C() {
  return (
    <VStack w="full" align="start" spacing={{ laptop: '200px', tablet: '120px', mobile: '190px' }}>
      <VStack w="full" align="start" spacing={{ laptop: '80px', tablet: '64px', mobile: '48px' }}>
        <Text as="h1" hideBelow="laptop" maxW={{ laptop: '800px' }}>
          1С-решения <br /> для автоматизации бизнеса
        </Text>
        <Text as="h1" hideFrom="laptop" maxW={{ tablet: '630px' }}>
          1С-решения для <br /> автоматизации бизнеса
        </Text>
        <Button onClick={goToForm}>Оставить заявку</Button>
      </VStack>

      <Grid
        w="full"
        templateColumns={{ xxl: 'repeat(3,1fr)', xxm: 'repeat(2,1fr)', mobile: '1fr' }}
        templateRows={{ xxl: 'auto', xxm: 'repeat(2,auto)' }}
        gridGap={{ laptop: '20px', xxm: '16px', mobile: '12px' }}
        placeItems="center"
      >
        {greetingsCardsData?.map((val, i) => (
          <AdvantageCard key={val.title} {...val} {...greetingsCardsStyles[i]} />
        ))}
      </Grid>
    </VStack>
  );
}
