import logo_1c from 'assets/1c/logos/logo_1c_franchising.svg';
import love from 'assets/Icons/Love.svg';
import star from 'assets/Icons/Star.svg';

export const greetingsCardsData = [
  {
    icon: logo_1c,
    title: 'Официальный партнер 1С',
    text: 'ASTON — официальный партнёр 1С:Франчайзинг. Мы предоставляем клиентам комплексное обслуживание',
    link: 'https://1c.ru/rus/partners/franch.jsp?id=61514&mark=1',
  },
  {
    icon: love,
    title: 'Индивидуальный подход',
    text: 'Мы предлагаем индивидуальную разработку с учетом ваших бизнес-целей и интеграцию в действующий системный ландшафт',
  },
  {
    icon: star,
    title: 'Опытные специалисты',
    text: 'Готовы решить задачу любой сложности — от разработки конфигураций с нуля до перехода на 1С с иностранного ПО и сервисов',
  },
];

const h = { mobile: '280px', tablet: '260px', laptop: '360px', desktop: '440px' };
export const greetingsCardsStyles = {
  0: {
    bgColor: 'bg.neonLight',
    h,
    iconContainerStyle: {
      boxSize: 'unset',
      w: { mobile: '69px', laptop: '139px' },
      h: { mobile: '36px', laptop: '72px' },
    },
    iconStyle: {
      boxSize: 'unset',
      w: { mobile: '50px', laptop: '99px' },
      h: { mobile: '30px', laptop: '60px' },
    },
  },
  1: {
    bgColor: 'bg.white',
    border: '1.6px solid',
    borderColor: 'line.gray',
    iconContainerStyle: {
      bgColor: 'bg.gray',
    },
    h,
  },
  2: {
    bgColor: 'bg.gray',
    gridRow: { xxl: 'auto', laptop: '2' },
    gridColumn: { xxl: 'auto', xxm: '2 span' },
    h,
  },
};
