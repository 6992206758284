import React from 'react';

import Projects from 'widgets/Projects';
import ContactUs from 'widgets/ContactUs';
import Greetings from 'widgets/Greetings1C';
import PageMetaData from 'widgets/PageMetaData';
import Configurations from 'widgets/Configurations';
import DevelopmentCycle from 'widgets/DevelopmentCycle';
import ExpertiseCardsSlider from 'widgets/ExpertiseCardsSlider';
import Wrapper from 'shared/Wrapper';

import { contactUs1cData } from 'constants/contactUs';
import { projectsData } from 'constants/1C/projects/data';
import { STORAGE_URL_ASTONDEVS } from 'constants/Api/constants';
import { expertiseCardsSlider1cData } from 'constants/expertiseCardsSlider';

export default function Page1C() {
  return (
    <>
      <PageMetaData
        title="Внедрение решений 1С — ASTON"
        description="Разработка и сопровождение решений на платформе 1С. Официальный партнёр 1С:Франчайзинг."
        img={{
          src: `${STORAGE_URL_ASTONDEVS}/metaPreviews/1с.png`,
          alt: 'Превью для страницы 1С-разработка',
        }}
      />
      <Wrapper pt={{ desktop: '176px', laptop: '228px', tablet: '160px', mobile: '148px' }}>
        <Greetings />
        <ExpertiseCardsSlider {...expertiseCardsSlider1cData} />
        <Configurations />
        <DevelopmentCycle />
        {/* @ts-ignore */}
        <Projects {...projectsData} />
        <ContactUs {...contactUs1cData} />
      </Wrapper>
    </>
  );
}
